import axios from 'axios'
import {Toast} from 'vant'


const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 50000
})

/** 请求拦截器 */
service.interceptors.request.use(
    config => {
		Toast.loading({
			message: '加载中...',
			forbidClick: true
		});
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

/** 响应拦截器 */
service.interceptors.response.use(({config, data}) => {
    if (config.loading) {
        Toast.clear()
    }
    const {code, msg} = data
    if (code != 0) {
        console.log(data)
        Toast.fail(msg)
        return Promise.reject(new Error(msg || 'Error'))
    } else {
        return data
    }
},
error => {
    return Promise.reject(error)
})

export default service
